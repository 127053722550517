import React from "react";
import { Layout } from "../../components/Layout";
import goodFridayFire from "../../images/good-friday-fire.jpeg";
import outdoorService from "../../images/outdoor-service-2.jpg";
import waterBottles from "../../images/water-bottles.jpeg";
import baptismImg from "../../images/baptism-image.jpeg";
import {
  faCross,
  faChurch,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { Standout } from "../../components/Standout";

const OurMission = () => {
  return (
    <Layout>
      <main className="relative bg-white z-0 min-h-[80vh]">
        <img
          src={goodFridayFire}
          alt=""
          className="w-full h-[36rem] md:h-[48rem] object-cover z-0 absolute"
        />
        <div className="w-full h-[36rem] md:h-[48rem] z-10 bg-gray-500 absolute mix-blend-multiply opacity-60" />
        <div className="w-full h-[36rem] md:h-[48rem] z-20 relative">
          <div className="absolute w-full h-full flex flex-col items-center justify-center p-8">
            <h1 className="text-white text-5xl mt-16 md:mt-0 font-serif">Our Mission</h1>
            <h2 className="text-white text-2xl mt-2">
              The Gospel for every man, woman, and child.
            </h2>
          </div>
        </div>
        <div className="p-8 py-16 md:p-16 bg-sky-950">
          <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row w-full gap-8 md:gap-16 items-center">
            <div className="text-white text-2xl font-light leading-10 order-1 md:order-1">
              WellSpring Bible Church embraces the vision of increasing the
              worship of God. As a local church, we are committed to ensuring
              that every man, woman, and child in Alliance hear and see the
              Gospel of Jesus Christ on repeated occasions. We want to bring
              change to the Alliance area with the Gospel.
            </div>
            <img
              src={outdoorService}
              className="object-contain rounded-2xl shadow-xl order-2 md:order-2"
            />
            <img
              src={waterBottles}
              className="object-contain rounded-2xl shadow-xl order-4 md:order-3"
            />
            <div className="text-white text-2xl font-light leading-10 order-3 md:order-4">
              As Christ's co-laborers, we will share the gospel of Jesus Christ
              with our neighbors who do not yet know Him and invite them into
              new relationship with Him. We will love the community through
              service and good deeds, in the name of Jesus.
            </div>
            <div className="text-white text-2xl font-light leading-10 order-5 md:order-5">
              We desire to unite and disciple local believers into a fully
              functioning, church body, and then, to send out and plant other
              churches in surrounding communities and beyond for the Glory of
              God.
            </div>
            <img
              src={baptismImg}
              className="object-contain rounded-2xl shadow-xl order-6 md:order-6"
            />
          </div>
        </div>
        <div className="py-16 bg-cyan-700">
          <h1 className="text-5xl px-8 pb-16 text-white font-serif text-center">
            Our Values
          </h1>
          <div className="grid grid-flow-row grid-cols-1 gap-8 md:grid-cols-3 px-4">
            <Standout
              title="Encounter Jesus"
              icon={faCross}
              content="We ﬁnd true intimacy in a relationship with our Savior, the Lord Jesus Christ."
              light
            />
            <Standout
              title="Embrace Community"
              icon={faChurch}
              content="We embrace the community of believers, Christ's church, and walk through life together with oneness in the Lord."
              light
            />
            <Standout
              title="Engage Culture"
              icon={faEarthAmericas}
              content="We seek to interact and engage with the culture in which we live, leading people into a life changing relationship with Jesus."
              light
            />
          </div>
        </div>
        <div className="w-full bg-gray-100 p-8 md:p-16">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 justify-between">
            <div className=" text-cyan-900 text-3xl font-light">
              Learn about what we believe and teach
            </div>
            <a
              href="/about/our-beliefs"
              className="rounded-full   bg-cyan-700 hover:bg-cyan-800 text-white text-sm sm:text-lg px-8 py-3"
            >
              See our beliefs
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default OurMission;
